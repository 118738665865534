@font-face {
  font-family: "Helvetica-neue";
  src: url("./assets/fonts/Helvetica-Neue-Font/Helvetica-normal/Helvetica-regular.ttf");
}

/* GLOBAL */

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Helvetica-neue";
  color: rgb(10, 90, 133);
  font-weight: bold;
  background-color: #effffa;
  height: 100vh;
  font-size: 1.5rem;
  position: relative;
}

.size1 {
  font-weight: bolder;
  margin-top: 0;
  font-size: 2.5rem;
}
.size2 {
  font-size: 1.7rem;
}
.size3 {
  font-size: 1.4rem;
}
.size4 {
  font-size: 1.2rem;
}
.size5 {
  font-size: 0.9rem;
}

.basicButton {
  cursor: pointer;
}

.basicButton:focus {
  box-shadow: #055c5c 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px;
}

.basicButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    #055c5c 0 -3px 0 inset;
  transform: translateY(-2px);
}

/* POP UP */

.basic-popup-button {
  background-color: rgb(10, 90, 133);
  color: #ffffff;
  font-size: 2em;
  font-weight: bold;
  border: 1px solid #282c34;
  border-radius: 25px;
  width: 15%;
  margin-top: 2%;
}

.image-popup,
.basic-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 5% 5% 5%;
  margin: 12%;
}

.image-popup-border,
.image-popup-text,
.digipad-code,
.text-in-popup {
  border-radius: 25px;
  text-align: center;
  padding: 2% 3%;
}

.image-popup-text,
.digipad-code,
.text-in-popup {
  background-color: #ece9e9;
  border: 1px solid #ece9e9;
}

.painting-test {
  margin-left: 500px;
}

/* IF ON A PHONE */

#phone-container {
  margin: 0 auto;
  height: 100vh;
  background-color: #effffa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#phone-container h1 {
  width: 80%;
  font-size: 2rem;
  color: rgb(10, 90, 133);
  text-align: center;
}

#phone-container svg {
  height: 20vh;
  width: auto;
}

/*  HOME PAGE */
.home {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/images/room3/Background.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  position: relative;
}
.heading {
  position: absolute;
  top: 12.5%;
  left: 50%;
  transform: translate(-50%, -12.5%);
  width: 80vw;
  height: 45%;
  overflow: auto;
  margin-top: 2vh;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.3) 0% 0% no-repeat padding-box;
  border: 3px solid rgba(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 44px;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  z-index: 1;
  color: black;
}

/* hide scrollbar */
.heading::-webkit-scrollbar {
  display: none;
}

.heading {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.home h1 {
  font-family: Helvetica-neue-bold;
}

.home h2 {
  font-weight: normal;
}

.home .flex {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -20%);
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.home .flex .link {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 20vw;
  height: 25vh;
}

.home .flex .link svg {
  width: 30px;
  height: 30px;
}

.home .flex a {
  text-align: center;
  border-radius: 25px;
  padding: 0 16px 0 16px;
  text-decoration: none;
  color: black;
}

.home .flex .link:hover {
  background-color: #000000a9 !important;
}

.home .flex #room1 {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.491),
      rgba(161, 192, 206, 0.646)
    ),
    url("./assets/images/room1/Background.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room1:hover {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.952),
      rgba(161, 192, 206, 0.847)
    ),
    url("./assets/images/room1/Background.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room2 {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.491),
      rgba(161, 192, 206, 0.646)
    ),
    url("./assets/images/room5/Salle05_Bg.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room2:hover {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.952),
      rgba(161, 192, 206, 0.847)
    ),
    url("./assets/images/room5/Salle05_Bg.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room3 {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.491),
      rgba(161, 192, 206, 0.646)
    ),
    url("./assets/images/room8/BG.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room3:hover {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.952),
      rgba(161, 192, 206, 0.847)
    ),
    url("./assets/images/room8/BG.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room4 {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.491),
      rgba(161, 192, 206, 0.646)
    ),
    url("./assets/images/room9/Room_9_BG.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

.home .flex #room4:hover {
  background: linear-gradient(
      0deg,
      rgba(161, 192, 206, 0.952),
      rgba(161, 192, 206, 0.847)
    ),
    url("./assets/images/room9/Room_9_BG.png") 0% 0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
}

/* ROOM 1 */

/* LEVEL  1 */

.container-level1 {
  position: relative;
  color: black;
}

.container-level1 .popup-button {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 70%;
}

.container-level1 .popup-button .container-text,
.container-DepartureVacation .popup-button .container-text {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1em;
  border-radius: 100px;
}

.container-level1 .popup-button .container-text h2 {
  margin: 0 0 1em 0;
}

.container-level1 .popup-button .container-text p {
  font-weight: normal;
  margin: 0;
}

.container-level1 .popup-button button {
  background-color: #055c5c;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  color: white;
}

.container-level1 .container-objFound {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100vh;
}

.container-level1 .container-objFound div {
  width: 15vh;
  height: 15vh;
  margin-left: 1em;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5);
}

.container-level1 .container-objFound div img {
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.container-level1 .container-images {
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 0px 6px #ffffff40;
}

.defaultCursor {
  cursor: default;
}

.transparent {
  background-color: transparent;
  border: none;
}

.container-level1 .result,
.room3Container .result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.container-level1 .result .popup-book .title_button {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: white;
  margin: 0;
  padding: 20px;
  border-radius: 50px;
}

.container-level1 .result .firstImage {
  max-width: 20vw;
  max-height: auto;
  object-fit: contain;
  cursor: default;
}

/* Quizz */
.bg-room1 {
  background: url("./assets/images/room1/Background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.quizz-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.quizz-container .question {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #31716a;
}

.quizz-container .question .container_text {
  font-weight: bold;
  background-color: white;
  color: black;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  font-size: 1.3rem;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  width: 80%;
}

.quizz-container .reponses {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.quizz-container .reponses .image-popup,
.container-level1 .result .image-popup,
.container-level1 .result .popup-book {
  width: 30%;
  padding: 15px;
  margin: 0;
}

.quizz-container .reponses .image-popup button,
.container-level1 .result .image-popup button {
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 15px;
  width: 75%;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}

.quizz-container .reponses .image-popup p {
  height: 55px;
  width: 90%;
}

.container-level1 .result .image-popup p {
  height: 50px;
  width: 80%;
}

.quizz-container .reponses .image-popup p,
.container-level1 .result .image-popup p {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  color: black;
  padding: 7px 12px;
}

.quizz-container .reponses .image-popup button img,
.container-level1 .result .image-popup button img,
.container-level1 .result .popup-book input[type="image"] {
  width: 70%;
  object-fit: contain;
  border: none;
}

.quizz-container .result {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 3;
}

.quizz-container .popup-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 25px 15px;
  border-radius: 50px;
}

.quizz-container .result .popup-button .container-text {
  border-radius: 75px;
}

.container-level1 .result .popup-book {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-level1 .result .popup-book input[type="image"] {
  width: 80%;
  background-color: transparent;
}

/* Quizz Res */
.container-quizzRes {
  position: relative;
  color: black;
}

.container-quizzRes .container_text {
  background: rgba(255, 255, 255, 0.4) 0% 0% no-repeat padding-box;
  border: 3px solid rgb(255, 255, 255, 0.6);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(50px);
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  width: 45%;
  margin-left: 10px;
  padding: 15px 25px;
  border-radius: 50px;
  text-align: center;
  font-weight: normal;
}

.container-quizzRes .image-popup {
  position: absolute;
  margin: 0;
  padding: 15px 0;
}

.container-quizzRes .image-popup button {
  background-color: white;
  border: none;
  border-radius: 40px;
  padding: 15px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}

.container-quizzRes .image-popup button img {
  width: 60%;
  background-color: transparent;
  border: none;
}

.container-quizzRes .image-popup p {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-size: 1rem;
  background-color: white;
  border: none;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}

.container-quizzRes .container_anim > button[type="submit"] {
  position: absolute;
  display: inline;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: white;
  background-color: #065b5b;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
}

/* PAD / REBUS PAGE */

/*.pad {
  position: relative;
   background: url("./assets/images/room1/Rebus.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  height: 100vh;
  width: 100vw;
} */

.pad .popup-button,
.pad .affichePopUp .popUpCorrect {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0) 0% 0% no-repeat padding-box;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 3;
}

.pad .affichePopUp .popUpCorrect {
  background: rgba(255, 255, 255, 0.5) 0% 0% no-repeat padding-box;
  border: 3px solid rgb(255, 255, 255, 0.6);
  text-align: center;
  border-radius: 89px;
  backdrop-filter: blur(50px);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  color: black;
  margin-bottom: 15px;
  padding: 25px 20px;
}

.pad .affichePopUp > button[type="submit"] {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: #055c5c;
  border: none;
  padding: 10px 40px;
  border-radius: 50px;
  outline: none;
}

.pad .popup-button {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pad .popup-button .text-container {
  padding: 15px 35px;
  font-size: 1.1rem;
}

.pad .popup-button .container-text {
  border-radius: 100px;
}

.pad-elements {
  position: absolute;
}

.pad-buttons {
  position: absolute;
  color: #223c59;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pad-buttons-row {
  background-color: rgba(0, 255, 0, 0.8);
  position: relative;
  height: 85px;
}

.validate-button {
  position: absolute;
  background-color: transparent;
  color: #19372b;
  font-size: 2em;
  font-weight: bold;
  border: 1px solid #282c3400;
  border-radius: 5px;
}

.pad-button {
  background-color: transparent;
  font-weight: bold;
  border: 1px solid #282c34;
  border-radius: 5px;
  position: absolute;
}

.monitor {
  position: absolute;
  color: #000000;
  font-weight: bold;
  letter-spacing: 0.62vw;
}

.monitor p {
  margin: 0;
  position: absolute;
  transform: translate(0, -50%);
  left: 2%;
  top: 65%;
  font-size: 1.8vw;
}

.pad .affichePopUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

/* PAd access */

.container-padAccess {
  background-image: url("./assets/images/room1/Salle01_Background.png");
}

.container-padAccess button[type="submit"] {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 0;
  font-size: 3rem;
  cursor: pointer;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.6);
}

.container-padAccess .plante {
  z-index: 3;
}

.absolute-contain {
  position: absolute;
  object-fit: contain;
}

.absolute-contain img {
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

/* CSS POUR LA ROOM 2, LEVEL 1 */

.back_level_1 {
  background-image: url("./assets/images/room2/Salle2_WithoutDoor_FullHD.png");
  background-size: 100% 100vh;
  height: 100vh;
  position: relative;
  font-weight: normal;
}

.pop_up_level_1 {
  position: absolute;
  padding: 1% 3%;
  background-color: white;
  text-align: center;
  border-radius: 30px;
  font-size: 120%;
  width: 40vw;
  bottom: 15%;
  left: 25%;
}

.back_level_1 .popup-button {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.back_level_1 .popup-button .container-text {
  border-radius: 50px;
}
.tableauclick {
  border: none;
  background-size: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 17%;
  height: 40%;
  top: 20%;
  left: 16%;
  z-index: 2;
}

.tableudopamine {
  position: absolute;
  width: 17%;
  height: 40%;
  top: 20%;
  left: 16%;
  z-index: 1;
  border: none;
}

.tableudopamine:focus {
  outline: none;
}

/* ROOM 2 LEVEL 2 */

.test {
  border: 1px solid red;
  width: 100%;
  height: 100vh;
}

/* Va contenir toutes mes div*/
.room2-container,
#roomHeroe {
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  background-image: url("./assets/images/room2/Tableau_Dopamine/tableau_dopamine_FullHD.png");
  background-position: center;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

#roomHeroe {
  background-image: url("./assets/images/room5/TableauSalle05/Neurons.png");
}

/*div du haut, qui contient les deux textes*/
.top-container {
  width: 80%;
  height: 35%;

  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
}

/*div qui englobe les textes*/
.room2-container .pJonction,
#roomHeroe .pJonction {
  width: 45vw;
  padding: 15px 20px;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  border-radius: 15px;
}

.bottom-container .text-container {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  width: 35%;
  background-color: white;
  border-radius: 15px;
  padding: 1%;
  text-align: center;
}

/*id pour décaller et changer la doucleur du deuxieme texte*/
#text-2 {
  margin: 0 auto;
  color: #ee7d95;
  z-index: 2;
}

/*div qui englobe l'emeteur*/
.gradient-container {
  border: 1px solid red;
  width: 30%;
  height: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /*position relative pour les positions absolutes sur les dopamines*/
  position: relative;
}

#gradient {
  width: 100%;
}

/*div qui englobe le recepteur*/
.vector-container {
  border: 1px solid red;
  width: 30%;
  height: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
}

#vector {
  width: 100%;
}

#orange-arrow {
  width: 10%;
  position: absolute;
  left: 16%;
  top: 36%;
}

#dopamine-container1 {
  position: absolute;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 39.5%;
  left: 34.5%;
  width: 2.4%;
}

#dopamine-container2 {
  position: absolute;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 47%;
  left: 36.5%;
  width: 2.4%;
}

#dopamine-container3 {
  position: absolute;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  top: 55.3%;
  left: 35%;
  width: 2.4%;
}

.dopamine {
  width: 100%;
}

.room2-container #arrowBis {
  position: absolute;
  top: 47.5%;
  width: 9%;
}

#roomHeroe #arrowBis {
  position: absolute;
  top: 57.5%;
  width: 9%;
}

.bottom-container {
  width: 100%;
  margin: 0 auto;
}

/* ROOM 2 LEVEL 2.2 */

.container2-2 {
  background-image: url("./assets/images/room2/Tableau_Dopamine/Frame.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
}

.container2-2 .popup-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
}

.container2-2 .popup-button .container-text {
  border-radius: 75px;
}

.top-containerBis {
  width: 100%;
  height: 30%;
}

.middle-container {
  width: 100%;
  height: 40%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 35%;
}

.img-container {
  width: 33%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#newDopa {
  width: 30%;
  display: block;
  margin: auto;
}

.pos-initial1 {
  top: 38;
  left: 39.5;
}

.contenent {
  background-position: bottom;
  background-size: 82%;
  font-size: 150%;
}

/* CSS POUR LA ROOM 2, LEVEL 3 */

.back_level_3 {
  background-image: url("./assets/images/room2/template.png");
  background-size: 100% 100vh;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

.back_level_3 button {
  margin-bottom: 2em;
}

.pop_up_level_3 {
  position: absolute;
  padding: 10px 30px;
  background-color: white;
  text-align: center;
  border-radius: 60px;
  font-size: 120%;
  min-width: 20vw;
  bottom: 30%;
  left: 20%;
}

/* CSS POUR LA ROOM 2, LEVEL 4 */

.pop_up_level_4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-weight: normal;
}

.pop_up_level_4 .popup-button .container-text {
  width: 60vw;
  border-radius: 50px;
}

.containerBtnHeart {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.containerGauge {
  position: absolute;
  top: 10%;
  right: 0;
  width: 25vw;
  height: 80vh;
}

.visible {
  /* display: inline-block; */
  visibility: visible;
}

.hidden {
  /* display: none; */
  visibility: hidden;
}

.sousDivGauge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 70vh;
}

.end {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btndopa {
  width: 5%;
}

.backgroundfrontview {
  width: 100%;
  height: 100vh;
  background-image: url("./assets/images/room2/Room02_FrontView/Room02_FrontView.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}

.perso_img {
  position: absolute;
  bottom: 0;
  right: 55%;
  height: 80%;
}

.imgjauge {
  height: 100%;
}

/* CSS POUR LA ROOM 3, LEVEL 1 */

.room3Container {
  background-color: black;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
  transition: all 0.2s ease 0s;
  font-weight: normal;
}

.room3Container .popup-button {
  width: 70vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.room3Container .popup-button .container-text {
  border-radius: 60px;
}

.room3Container .result {
  background-color: rgba(0, 0, 0, 0.6);
}

.room3Container .result .popup-button {
  background-color: white;
  padding: 15px 20px;
}

.room3Container .result .popup-button button[type="submit"] {
  font-weight: bold;
}

#background-template {
  background-image: url("./assets/images/room3/back.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  flex-direction: column;
}

#background-template2 {
  background-image: url("./assets/images/room3/back.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: flex-end;
}

#background-template3 {
  background-image: url("./assets/images/room3/back.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  flex-direction: column;
}

.room3-sous-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 85%;
  height: 90%;
  background-image: url("./assets/images/room3/Tableau_AssetsSepares/Tableau.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

#new-tab {
  /* background-image: url("./assets/images/room3/Tableau_Complet/tableau_3_voies.png"); */
  position: relative;
  z-index: 2;
}

.filter {
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.divRadius {
  position: absolute;
  top: 2%;
  left: 50%;
  transform: translate(-50%, -2%);
  text-align: center;
  font-weight: normal;
  padding: 10px 15px;
  width: 60%;
  text-align: center;
}

.divRadius h1 {
  padding-bottom: 1%;
}

.room3-p2 {
  width: 80%;
  margin: 0 auto;
}

.room3-btn-container {
  margin: 0 auto;
}

/* CSS POUR LA ROOM 3, LEVEL 1.2*/

.room3ContainerColor {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100vh;
  transition: all 0.2s ease 0s;
  background-image: url("./assets/images/room3/Background.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  z-index: -2;
  font-weight: normal;
}

.surbrillance {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.leftSide {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  margin: 0;
  position: relative;
}

.panel {
  width: 100%;
  height: 90%;
  margin: 0 auto;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 2%;
  z-index: 2;
}

.left-tableau-container {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}

.brain-container {
  width: 55%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}

#brain-container-ajust {
  width: 100%;
  height: 50%;
  margin-top: 20%;
}

.panel-container {
  height: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.panel-container p {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 80%;
  text-align: center;
  z-index: 3;
}

.popupContainer {
  width: 50%;
  position: relative;
}

.popupContainer .popUpVoies {
  margin: 2vh auto;
  width: 95%;
}

.popupContainer > .basicButton {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.containerPath {
  position: absolute;
  top: 65%;
  z-index: -2;
  width: 100vw;
}

.containerPath > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.containerBushes {
  position: absolute;
  top: 67%;
  z-index: 0;
  width: 100vw;
}

.containerBushes > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.chessPlayerBack {
  width: 40%;
  position: absolute;
  bottom: 19%;
  left: 55%;
  z-index: 1;
}

.winnerBack {
  width: 40%;
  position: absolute;
  bottom: 2%;
  left: 14%;
  z-index: 2;
}

.Bush {
  width: 40%;
  position: absolute;
  bottom: 10%;
  left: 60%;
  z-index: 2;
}

.walkerCharacter {
  width: 18%;
  height: auto;
  position: absolute;
  top: 46%;
  left: 0%;
  z-index: 0;
}

.div-brain-container {
  margin: auto;
  z-index: 3;
}

.brain-container2 {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}

#marginAjust {
  margin: auto auto 12% auto;
}

.brain {
  width: 75%;
  display: inline-block;
  margin: 0 auto;
}

.popupContainer .popup-button {
  position: absolute;
  margin-top: 2em;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 95%;
  text-align: center;
  z-index: 4;
}

.popupContainer .popup-button .container-text {
  border-radius: 50px;
}

.bouton-container {
  margin-top: 2%;
}

#row {
  flex-direction: row;
}

.brainConnexionRed {
  width: 72%;
  position: absolute;
  top: 1%;
  left: 14%;
}

.brainConnexionRed:hover,
.brainConnexionBlue:hover,
.brainConnexionPurple:hover {
  transform: scale(1.1);
}

.brainConnexionBlue1-2 {
  position: absolute;
  top: 31%;
  left: 35%;
  width: 17%;
  z-index: 1;
}

.brainConnexionBlue {
  position: absolute;
  top: 52%;
  left: 35%;
  width: 17%;
  z-index: 1;
}

.brainConnexionPurple1-2 {
  position: absolute;
  top: 19%;
  left: 36%;
  width: 17%;
}

.brainConnexionPurple {
  position: absolute;
  top: 31%;
  left: 36%;
  width: 17%;
}

.imgWinner {
  height: 35%;
  width: 40%;
  margin: 0 auto;
}

/* LEVEL 1.5 */

.question {
  width: 60%;
  margin: 3% auto;
  text-align: center;
  color: white;
}

#brainTextContainer {
  width: 90%;
  margin: 0 auto;
}

.divQuestionPurple {
  width: 25%;
  height: 50%;
  transition: all 0.2s ease 0s;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  border: none;
}

.divQuestionPurple2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: column;
  transition: all 0.2s ease 0s;
  background-color: white;
  position: absolute;
  top: 25%;
  left: 27%;
  width: 45%;
  height: 30%;
}

.divQuestionPurple2 p {
  text-align: center;
  font-size: 0.8;
  padding: 2%;
  line-height: 140%;
  margin: 0 auto;
}

.divQuestionPurple:hover {
  transform: scale(1.05);
}

.divQuestionRed {
  display: flex;
  flex-wrap: wrap;
  width: 25%;
  height: 50%;
  border-radius: 15px;
  transition: all 0.2s ease 0s;
  position: absolute;
  bottom: 20%;
  right: 0;
  background: transparent;
  border: none;
}

.divQuestionRed:hover {
  transform: scale(1.05);
}

.divQuestionBlue {
  width: 38%;
  position: absolute;
  top: 50%;
  left: 0;
  height: 30%;
  border-radius: 15px;
  transition: all 0.2s ease 0s;
  background: transparent;
  border: none;
}

.divQuestionBlue:hover {
  transform: scale(1.05);
}

/* #purple {
  border: 2px solid #a42ef4;
  color: #a42ef4;
}

#blue {
  border: 2px solid #1a79c7;
  color: #1a79c7;
}

#red {
  border: 2px solid #d62d6a;
  color: #d62d6a;
} */

#perso-winner {
  position: absolute;
  width: 20%;
  bottom: 2%;
  left: 57%;
  z-index: 1;
}

#boutonSuite {
  transition: all 0.2s ease;
  position: absolute;
  bottom: 2%;
  right: 7%;
  z-index: 3;
}

#boutonSuite:hover {
  transform: scale(1.05);
}

.hide {
  display: none;
}

.btn-container {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

/* LEVEL 1.6 */
.container-bonne-rep {
  width: 50vw;
  height: 50vh;
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -22%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container-bonne-rep .basicButton {
  margin-top: 2vh;
}
.bonne-reponse {
  text-align: center;
  color: black;
  font-weight: normal;
  border: 3px solid #a41ef4;
  border-radius: 50px;
  padding: 1vw;
}

.bonne-reponse ul {
  list-style: none;
  padding: 0;
}

.bonne-reponse ul li {
  font-weight: bold;
}

.popupContainer2 {
  width: 55%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}

.endContainer {
  background-image: url("./assets/images/room3/template.png");
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.endContainer2 {
  width: 40%;
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: white;
  text-align: center;
  color: black;
}

.endContainer p {
  width: 100%;
  font-size: 2rem;
}

/* ROOM 4 */

.room4Container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-image: url("./assets/images/room4/Backgroundfull.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  z-index: 1;
  font-weight: normal;
}

.room4Container .popup-button {
  width: 50%;
  margin: auto auto;
}
.room4Container .popup-button .container-text {
  border-radius: 60px;
  margin: 20px;
  width: 100%;
}

.div-side {
  width: 33%;

  display: flex;
  flex: wrap;
  flex-direction: column;
  justify-content: flex-end;
}

.div-middle {
  width: 26%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

#div-middle-top {
  height: 15%;
  width: 100%;
}

#div-middle-bottom {
  height: 55%;
}

.sous-div-middle {
  width: 210%;
  height: 30%;
}

.sous-div-side {
  width: 100%;
  height: 50%;
  position: relative;
}

#imgFlower {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 15%;
}

.flower-back {
  background-image: url("./assets/images/room4/Flower_Step09.png");
  background-repeat: no-repeat;
  background-size: 10vh;
  background-position: 47% 24%;
}

#sous-div-side-right {
  height: 60%;
}

#sous-sous-div-side-right {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 80%;
}

.room4-bouton-div {
  border: none;
  background: none;
  width: 50%;
  height: 70%;
  cursor: pointer;
}

#poterie-button {
  margin-left: 35%;
}

#calligraphie-button {
  margin-top: 20%;
}

#accrobranche-right-button {
  height: 15%;
  width: 83%;
  margin-top: 10%;
}

#accrobranche-mid-button {
  height: 85%;
  width: 37%;
  margin-top: 20%;
}

#accrobranche-left-button {
  height: 25%;
  width: 55%;
  margin-left: 45%;
}

.poteriePopup-contain {
  width: 100vw;
  height: 100vh;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.poterie-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
  background-image: url("./assets/images/room4/Pottery_BG.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  justify-content: space-evenly;
  font-weight: normal;
}

.flower-container {
  width: 30%;
  margin: 2% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background: none;
  border: none;
}

#flower {
  width: 34%;
  padding-bottom: 25%;
}

.poterie-sous-container-top {
  width: 100%;
  height: 27%;
}

.poterie-sous-container-middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 33%;
}

.poterie-sous-container-bottom {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  min-height: 33%;
}

#div-absolute {
  position: fixed;
  top: 20%;
  left: 25%;
}

.endRoom4-container {
  font-weight: normal;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: black;
}

.divRadius-end-room4 {
  background-color: white;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  padding: 2%;
}

/* ROOM 4 caligraphie */

.caligraphie-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-image: url("./assets/images/room4/Calligraphy.png");
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  font-weight: normal;
}

.caligraphie-top-container {
  width: 100%;
  height: 40%;
}

.divRadius-caligraphie {
  text-align: center;
  color: black;
  width: 45%;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.canvas {
  position: absolute;
  bottom: 4%;
  left: 30.5%;
  z-index: 1;
}

#gomme {
  position: absolute;
  bottom: 8%;
  left: 64.3%;
  background: none;
  border: 1px solid black;
  padding: 0.3vw;
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#arrow-right {
  position: absolute;
  bottom: 14%;
  left: 64.3%;
  background: none;
  border: 1px solid black;
  padding: 0.3vw;
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caligraphie-popup {
  position: absolute;
  bottom: 4%;
  left: 30.5%;
  z-index: 2;
  padding: 0.5vw;
  line-height: 150%;
  background-color: rgba(255, 255, 255, 1);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.caligraphie-popup-text {
  margin: 0.5vh 0;
}

#btn-suivant-caligraphie {
  text-decoration: none;
  background-color: #f4adb4;
  border: none;
  padding: 1%;
  border-radius: 15px;
  width: 20%;
  margin: 3% auto;
}

/* ROOM 4 LEVEL 3 */

.room4-level3-container {
  background-color: white;
  border-radius: 50px;
  width: 80%;
  height: 80%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 150%;
  color: #f4adb4;
  font-size: 1.1em;
  /* background-image: url("./assets/images/room4/Infographie/Infographie.png");  */
  background-repeat: no-repeat;
  background-size: 75% 75vh;
  background-position: center;
}

.div-condition {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.divTrue {
  background-color: white;
  padding: 2%;
  border-radius: 50px;
  color: black;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

#div-kurwa {
  text-align: center;
}

/* ROOM 4 Accrobranche*/
.room4ContainerAccro {
  background: url("./assets/images/room4/Accrobranche.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.room4ContainerAccro #containerTopCharacter {
  position: absolute;
}

.room4ContainerAccro #containerBottomCharacter {
  position: absolute;
}

.room4ContainerAccro > .popup-button {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: black;
}

.room4ContainerAccro .container-messageFin .popup-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: black;
  width: 100%;
  font-weight: normal;
}

.room4ContainerAccro .popup-button .container-text {
  background-color: white;
  padding: 10px 25px;
  width: 45vw;
  text-align: center;
}

.room4ContainerAccro .popup-button .container-text h2 {
  font-weight: normal;
}

.room4ContainerAccro .popup-button button {
  background-color: #ffc9b8;
  font-weight: bolder;
  margin-top: 1em;
}

.room4ContainerAccro .containerTitleAct {
  width: 50%;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
}

/* CSS POUR LA ROOM 5*/

.container-enzyme-absolute {
  position: absolute;
  z-index: 0;
}

.container-enzyme-absolute > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* Classes géneriques */
.containerNextRoom {
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: relative;
}

.containerNextRoom .container-elements {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.containerNextRoom .container-elements > .container-img {
  width: 30%;
  height: 30%;
}

.containerNextRoom .container-elements .container-img img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.inventory {
  background-color: #50948bd5;
  border: solid #055c5c;
  border-radius: 55px;
  backdrop-filter: blur(20px);
  position: absolute;
  top: 80%;
  left: 85%;
  transform: translate(-50%, -50%);
  width: 25%;
  height: 30%;
}

.inventory .imgdoc1,
.inventory .imgdoc2,
.inventory .imgdoc3 {
  width: 10%;
  height: 70%;
  position: relative;
  top: 15%;
}

.imgdoc1 {
  left: 15%;
}

.imgdoc2 {
  left: 35%;
}

.imgdoc3 {
  left: 55%;
}

/* CSS POUR LEVEL 1*/

.back_level_1_room_5 {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/images/room5/Salle05_Bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  font-weight: normal;
  font-size: 1.2em;
  position: relative;
}

.pop_up_level_1_room_5 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
}

.pop_up_level_1_room_5 h1 {
  font-size: calc(14px + 0.390625vw);
}

.pop_up_level_1_room_5 p {
  font-size: calc(10.5px + 0.390625vw);
}

.imagemed {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 50px;
  border: 3px;
  margin-bottom: 3%;
  padding: 5% 0 5% 0;
  background-color: white;
  box-shadow: #282c34 3px 3px 3px;
}

.pop_up_level_1_room_5 .imagemed .container-img {
  height: 25vh;
}

.pop_up_level_1_room_5 .imagemed .container-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.document {
  transform: rotate(35deg);
}

.imagemed .document {
  width: 10%;
  height: 20%;
}

.imagemed .boite {
  padding-top: 4%;
  width: 20%;
  height: 100%;
}

.back_level_1_room_5 > .popup-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  padding: 15px 10px;
  text-align: center;
}

.back_level_1_room_5 .popup-button .container-text {
  background-color: white;
  border-radius: 60px;
  padding: 3% 5% 3% 5%;
  margin-bottom: 5%;
  box-shadow: #282c34 3px 3px 3px;
}

.back_level_1_room_5 .popup-button button[type="submit"] {
  padding: 2% 5%;
  background-color: #1c4371;
  color: white;
  border-radius: 30px;
}

.button_med_level_1_room5 {
  background-color: rgb(10, 90, 133, 0);
  border: none;
  color: white;
  font-size: 100%;
  width: 100%;
  height: 100%;
}

.medicaments {
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: grab;
}

.paracétamol {
  position: absolute;
  bottom: 20%;
  left: 29%;
  width: 5%;
  height: 12vh;
  background-color: rgb(10, 90, 133, 0);
  color: white;
  text-align: center;
}

.Levodopa {
  position: absolute;
  bottom: 20%;
  left: 36.5%;
  width: 5%;
  height: 12vh;
  background-color: rgb(10, 90, 133, 0);
  color: white;
  text-align: center;
}

.dopaminergiques {
  position: absolute;
  bottom: 20%;
  left: 51.5%;
  width: 5%;
  height: 12vh;
  background-color: rgb(10, 90, 133, 0);
  color: white;
  text-align: center;
}

.Inhibiteurs {
  position: absolute;
  bottom: 20%;
  left: 58.7%;
  width: 5%;
  height: 12vh;
  background-color: rgb(10, 90, 133, 0);
  color: white;
  text-align: center;
}

/* CSS POUR LEVEL 1.2*/

.choix_med {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #50948bd5;
  border: solid #055c5c;
  border-radius: 55px;
  backdrop-filter: blur(20px);
  color: black;
  padding: 2% 3% 2% 3%;
  width: 90%;
  height: 70%;
  text-align: center;
  border-radius: 3em;
  font-size: 1.5em;
}

.choix_med img {
  height: 30vh;
  margin: 5% 0 0% 0;
}

.choix_med .parchemin-button2 {
  position: absolute;
  top: 60%;
  left: 20%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.choix_med .parchemin-button1 {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.choix_med .parchemin-button3 {
  position: absolute;
  top: 60%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.inputmed {
  width: 100%;
  background-color: rgb(10, 90, 133, 0);
  border: none;
  cursor: pointer;
}

.inputmed img {
  transform: rotate(35deg);
}

.choix_med p {
  font-size: calc(10px + 0.390625vw);
}

.popupmed {
  background-image: url("./assets/images/room5/Document_Reveal.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  top: 15%;
  left: 10%;
  width: 50%;
  height: 70%;
  padding: 5%;
  color: #000000;
}

.popupmed h1 {
  font-size: calc(14px + 0.390625vw);
  margin-top: calc(2em + 0.2vw);
  text-align: center;
}

.popupmed ul {
  font-size: calc(10px + 0.390625vw);
}

.popupmed p {
  font-size: calc(10px + 0.390625vw);
  text-align: center;
}

.dual-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}

.popupmed .basicButton {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 15px;
  background-color: #055c5c;
  color: white;
  font-size: 1.5rem;
  border: 5px solid #055c5c;
  border-radius: 40px;
  width: 40%;
}

.level-parchemin-popup .basicButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 15px;
  background-color: #055c5c;
  color: white;
  font-size: 1.5rem;
  border: 5px solid #055c5c;
  border-radius: 40px;
}

/* CSS POUR LEVEL 2*/

.room5level2-container {
  background-image: url("./assets/images/room5/HumanBody_Scheme.png");
  background-color: #beddfa;
  background-position: top;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: 27%;
  position: relative;
}

.container-gelule,
.container-obj {
  position: absolute;
  z-index: 3;
}

.container-gelule > img,
.container-obj > img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.Digestif {
  height: 100vh;
}

.dopamine2 {
  position: absolute;
  bottom: 38vh;
  left: 45%;
  height: 6vh;
}

.dopamine2_2 {
  position: absolute;
  bottom: 80vh;
  left: 48%;
  height: 6vh;
}

.textBody {
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translate(-50%, -50%);
  max-width: 25%;
  font-size: calc(15px + 0.390625vw);
  background-color: white;
  padding: 15px 20px;
  border-radius: 25px;
  text-align: center;
}

/* Pour level 3.2 */

.agonistes img {
  animation-name: agonisteAnimation;
  animation-duration: 0.8s;
}

@keyframes agonisteAnimation {
  from {
    top: 100%;
    left: 50%;
  }

  to {
    top: 50%;
    left: 50%;
  }
}

/* Pour level 4.2 */
.container-disparitionEnzymes {
  background-image: url("./assets/images/room2/Tableau_Dopamine/Frame.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  height: 100vh;
  width: 100vw;
  color: white;
}

.container-disparitionEnzymes > .container-popUpEnd {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.container-disparitionEnzymes > .container-popUpEnd .popup-button button[type="submit"] {
  background-color: #1c4371;
}

.container-disparitionEnzymes > .pJonction {
  margin: 0;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.7rem;
  padding: 2% 2% 2% 2%;
}

.absolute {
  position: absolute;
}

.container-enzyme {
  z-index: 0;
}

.absolute > img,
.container-dopamine > img,
.container-enzyme > img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.container-disparitionEnzymes .container-inhibiteur {
  background-color: transparent;
  border: none;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
}

/* CSS POUR LA ROOM 6*/

.background-room6 {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/images/room6/Room06_BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100vh;
  font-weight: normal;
  font-size: 1.2em;
  position: relative;
}

.pop-up-room6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 40vw;
}

.message-room6 {
  color: black;
  font-weight: 600;
  background-color: white;
  border-radius: 70px;
  padding: 4% 8% 4% 8%;
  margin-bottom: 5%;
}

.message-room6 h1 {
  font-size: 1.4em;
}

/* CSS POUR LEVEL 1*/

/* CSS POUR LEVEL 2*/

.backgroundRoom6Level2 {
  position: relative;
  top: 0px;
  margin: 0px;
  background-color: #cde0fb;
  width: 100vw;
  height: 100vh;
}

.instruction {
  position: absolute;
  text-align: center;
  background-color: white;
  color: black;
  padding: 1%;
  border-radius: 45px;
  width: 50vw;
  top: 3vh;
  left: 25vw;
}

.instruction h1 {
  font-size: 3rem;
}

.instruction p {
  font-size: 2rem;
}

.btnSymptome {
  position: absolute;
  top: 35vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
}

.btnIllustration {
  position: absolute;
  top: 55vh;
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
}

.card-input {
  border: none;
  border-radius: 30px;
  width: 16%;
  height: 20vh;
  padding: "0";
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgb(0, 0, 0, 0);
}

/* CSS POUR LEVEL 3*/
.pop_up_level_3_room_6 {
  text-align: center;
  position: absolute;
  top: 30vh;
  left: 30vw;
  width: 40vw;
}

.back_level_3_room_6 {
  position: relative;
  top: 0px;
  margin: 0px;
  background-color: #cde0fb;
}
.message_level_3_room_6 {
  background-color: white;
  border-radius: 30px;
  padding: 2%;
  margin-bottom: 5%;
}

/* CSS POUR LEVEL 4 */
.background-room6,
.classMotion {
  background-color: #f99746;
}

.classMotion {
  border: solid 2px #f99746;
  width: 15%;
  height: 10%;
  border-radius: 30px;
  text-align: center;
}

.ButtonRoom6 {
  position: absolute;
  top: 50%;
  left: 20%;
}

/* ROOM 7 */

.container-vacation .popup-button {
  background-color: white;
}

.container-DepartureVacation {
  background: url("./assets/images/room7/Background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.container-DepartureVacation .result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.container-DepartureVacation .zoneDrag {
  background-color: green;
  max-width: 90vw;
  max-height: 90vh;
  min-width: 90vw;
  min-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-DepartureVacation .popup-button {
  position: absolute;
  width: 40vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  text-align: center;
}

.container-DepartureVacation .popup-button .container-text {
  background-color: white;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  border-radius: 50px;
  padding: 10px 10px;
  width: 50vw;
}

.container-DepartureVacation .popup-button .container-text h2 {
  margin: 0 0 1em 0;
}

.container-DepartureVacation .popup-button .container-text p {
  font-weight: normal;
  margin: 0;
}

.container-DepartureVacation .dopamine {
  position: absolute;
  z-index: 0;
}

.container-DepartureVacation .valises {
  position: absolute;
  z-index: 2;
}

/* ROOM 8 */
.container-flight {
  /* background: url("./assets/images/room8/BG.png"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.container-flight > .basicButton {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
}

.container-Tablette {
  background: url("./assets/images/room8/Vector Smart Object.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.container-Tablette .screenTablette {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  height: 60vh;
}

.container-Tablette .screenTablette > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.container-Tablette .screenTablette > div > .p_popup1 {
  padding: 10px 10px;
  border-radius: 0;
}
.container-Tablette .screenTablette > div > .p_popup2 {
  padding: 10px 20px;
  border-radius: 80px;
  text-align: center;
  width: 70%;
  background-color: #ffa08b;
  color: black;
}

.container-Tablette .screenTablette input[type="image"] {
  width: 60%;
  height: 60%;
  object-fit: contain;
  object-position: center;
}

.container-Tablette .screenTablette p {
  width: 80%;
  margin: 0;
  text-align: center;
}

.container-Tablette #dialog1Desc,
.container-Tablette #dialog2Desc {
  width: 100%;
}

.container-Tablette .screenTablette video {
  width: 70%;
  height: 80%;
}

/* ROOM  9*/

#riziere {
  background: url("./assets/images/room9/Riziere.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

#hotel {
  background: url("./assets/images/room9/Room_9_BG.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

#end {
  background: url("./assets/images/room9/EndScreen.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.blur-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: #2f2f2f30;
  z-index: 1;
}

.container-vacation .popup-button {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-vacation .popup {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-vacation .popup h2 {
  font-size: 1.5rem;
}

.container-vacation button {
  background-color: #9adbf0;
}

.container-vacation .popup-button .container-text {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  border-radius: 50px;
  padding: 10px 10px;
  width: 50vw;
}

.container-vacation .popup .container-text {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
  border-radius: 50px;
  padding: 10px 10px;
  /* width: 50vw; */
}
.container-vacation .basicButton:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
    #055c5c 0 -3px 0 inset;
  transform: translateY(-2px);
  border-radius: 55px;
}

.card-left {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}

.card-right {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
}

.tea-activity .card-left {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.tea-activity .popup-button {
  top: 50%;
  left: 70%;
}

.prescription {
  padding: 2% 0 0 0;
  /* display: flex;
  flex-direction: column; */
}

.options-list {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 90%;
}

.options-list p {
  font-size: 1.2rem;
  font-weight: 500;
}

.options-list button {
  font-size: 1rem;
}

.golden-rules {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.certificat .popup-button {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.end-top {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.end-top-video {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.end-middle {
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

@media screen and (min-width: 500px) {
  .popupmed {
    width: 30%;
  }

  .popupmed h1 {
    font-size: calc(1.2rem + 0.2vw);
  }

  .popupmed p {
    font-size: calc(0.8rem + 0.2vw);
  }

  .popupmed ul {
    font-size: calc(0.8rem + 0.2vw);
  }
}

@media screen and (min-width: 1024px) {
  /* General */
  .size1 {
    font-size: 2.7rem;
  }
  .size2 {
    font-size: 2.1rem;
  }
  .size3 {
    font-size: 1.5rem;
  }
  .size4 {
    font-size: 1.4rem;
  }
  .size5 {
    font-size: 1.1rem;
  }

  /* Home */
  .heading {
    width: 55vw;
  }
  .home .flex .link svg {
    width: 38px;
    height: 38px;
  }

  /* ROOM 1 */
  /* Level 1 */

  .container-level1 .popup-button .container-text {
    width: 80%;
  }

  /* Level 2 */
  .quizz-container .question .container_text,
  .pad .popup-button .text-container {
    width: 75%;
  }
  .quizz-container .reponses .image-popup button,
  .container-level1 .result .image-popup button {
    border-radius: 30px;
  }

  .quizz-container .reponses .image-popup p {
    border-radius: 50px;
  }

  /* Level 3 */

  .container-quizzRes .container_text {
    width: 40%;
  }
  .container-quizzRes .image-popup p {
    height: 35px;
    padding: 7px 3px;
  }

  /* Level 5 */
  .pad .popup-button {
    width: 60%;
  }

  /* ROOM 2 */
  /* Level 2.2 */
  .container2-2 .popup-button {
    width: 50vw;
  }

  /* ROOM 3 */
  /* Level 1.1 */
  .room3Container .popup-button {
    width: 60vw;
  }
  /* Level 1.2 */
  .popupContainer .popup-button {
    width: 80%;
  }

  .popupContainer .popUpVoies {
    width: 80%;
    padding: 1rem;
  }

  /* Level 1.4 */

  .bonne-reponse {
    width: 45vw;
  }
  /* ROOM 4 */
  /* Level 2 */
  .divRadius {
    width: 50%;
  }

  /* Level 3 */
  .room4Container .popup-button .container-text {
    width: 90%;
  }

  /* ROOM  7*/
  .container-DepartureVacation .popup-button .container-text {
    width: 45vw;
  }
}

@media screen and (min-width: 1440px) {
  /* General */
  .size1 {
    font-size: 3.2rem;
  }
  .size2 {
    font-size: 2.5rem;
  }
  .size3 {
    font-size: 2rem;
  }
  .size4 {
    font-size: 1.8rem;
  }
  .size5 {
    font-size: 1.3rem;
  }

  /* Home */
  .heading {
    width: 60vw;
  }
  .home .flex .link svg {
    width: 48px;
    height: 48px;
  }
  /* ROOM 1 */
  /* Level 1 */
  .container-level1 .popup-button .container-text {
    width: 70%;
  }

  /* Level 2 - quizz*/
  .quizz-container .question .container_text,
  .pad .popup-button .text-container {
    padding: 10px;
    border-radius: 60px;
    width: 70%;
  }

  .quizz-container .reponses .image-popup button,
  .container-level1 .result .image-popup button {
    border-radius: 40px;
  }

  .quizz-container .reponses .image-popup p {
    height: 60px;
    border-radius: 50px;
  }

  /* Level 3  */
  .container-quizzRes .container_text {
    width: 35%;
  }

  /* Level 5 */
  .pad .popup-button {
    border-radius: 70px;
    width: 50%;
  }

  .pad .affichePopUp .popUpCorrect {
    width: 40vw;
  }

  /* ROOM 2  */
  /* Level 2.2 */
  .container2-2 .popup-button {
    width: 45vw;
  }

  .container2-2 .popup-button .container-text {
    border-radius: 75px;
  }

  /* Level 4 */
  .pop_up_level_4 .popup-button .container-text {
    width: 40vw;
  }

  /* ROOM 3 */
  /* Level 1.1 */
  .room3Container .popup-button {
    width: 50vw;
  }
  /* Level 1.2 */
  .popupContainer .popup-button {
    width: 70%;
  }

  /* ROOM 4 */
  /* poterie */
  .divRadius {
    width: 40%;
  }

  /* Level 3 */
  .room4Container .popup-button .container-text {
    border-radius: 60px;
    width: 80%;
  }

  /* ROOM 5 */

  .popupmed {
    width: 30%;
  }

  .popupmed h1 {
    font-size: calc(1.5rem + 0.2vw);
  }

  .popupmed p {
    font-size: calc(1rem + 0.2vw);
  }

  .popupmed ul {
    font-size: calc(1rem + 0.2vw);
  }

  .back_level_1_room_5 > .popup-button {
    width: 50vw;
  }

  /* ROOM  7*/
  .container-DepartureVacation .popup-button .container-text {
    width: 35vw;
  }

  /* ROOM 9 */

  .options-list p {
    font-size: 1.2rem;
  }

  .options-list button {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1920px) {
  /* General */
  .size1 {
    font-size: 3.5rem;
  }
  .size2 {
    font-size: 2.2rem;
  }

  .size3 {
    font-size: 1.5rem;
  }
  .size4 {
    font-size: 1.4rem;
  }
  .size5 {
    font-size: 1.3rem;
  }

  /* Home */
  .heading {
    width: 50vw;
  }
  .home .flex .link svg {
    width: 60px;
    height: 60px;
  }

  /* ROOM 1 */
  /* Level 1 */
  .container-level1 .popup-button .container-text {
    width: 65%;
  }

  /* Level 2 - quizz*/
  .quizz-container .question .container_text,
  .pad .popup-button .text-container {
    padding: 10px;
    border-radius: 70px;
    width: 65%;
  }
  .quizz-container .reponses .image-popup button,
  .container-level1 .result .image-popup button {
    border-radius: 50px;
  }

  .quizz-container .reponses .image-popup p {
    height: 70px;
    border-radius: 50px;
  }

  .container-level1 .result .image-popup p {
    width: 70%;
  }

  /* Level 5 */
  .pad .popup-button {
    border-radius: 80px;
    width: 40%;
  }
  .back_level_1_room_5 > .popup-button {
    width: 40vw;
  }

  /* ROOM 2 */
  /* Level 1  */
  .back_level_1 .popup-button .container-text {
    width: 80%;
  }
  /* Level 2.2 */
  .container2-2 .popup-button {
    width: 40vw;
  }

  /* Level 4 */
  .pop_up_level_4 .popup-button .container-text {
    width: 35vw;
  }

  /* ROOM 3 */
  /* Level 1.1 */
  .room3Container .popup-button {
    width: 45vw;
  }

  /* LEvel 1.2 */
  .popupContainer .popUpVoies {
    width: 70%;
    padding: 1rem;
  }

  /* Level 1.4 */

  .bonne-reponse {
    width: 35vw;
  }
  /* ROOM 4 */
  /* poterie */
  .divRadius {
    width: 35%;
  }

  /* Calligraphie */
  #arrow-right,
  #gomme {
    padding: 0;
    width: 2.5vw;
    height: 2.5vw;
    padding: 0.2vw;
  }

  /* Level 3 */
  .room4Container .popup-button .container-text {
    width: 70%;
  }

  /* ROOM 5 */

  .choix_med {
    width: 70%;
  }

  .back_level_1_room_5 .basicButton {
    top: 87%;
    font-size: 2.5rem;
  }

  .container-disparitionEnzymes > .pJonction {
    width: 60%;
    padding: 2% 2% 2% 2%;
    font-size: 2.5rem;
  }

  .popupmed {
    width: 30%;
  }

  .popupmed h1 {
    font-size: calc(1.7rem + 0.2vw);
  }

  .popupmed p {
    font-size: calc(1.5rem + 0.2vw);
  }

  .popupmed ul {
    font-size: calc(1.5rem + 0.2vw);
  }

  /* ROOM 6 */
  .message-room6 p {
    font-size: 1.2em;
  }

  /* ROOM  7*/
  .container-DepartureVacation .popup-button .container-text {
    width: 30vw;
  }

  /* ROOM 9 */
  .container-vacation .popup h2 {
    font-size: 2.5rem;
  }

  .options-list p {
    font-size: 1.7rem;
  }
}

.screenreader-input {
  position: absolute;
  left: -10000px;
}

.agonistes-draggable div div img {
  cursor: grab !important;
}

.legend-ingibiteurs {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  margin: 0 35px 30px 0;
  background-color: rebeccapurple;
  display: flex;
  align-items: center;
}

.legend-ingibiteurs img {
  margin-right: 15px;
}

.viewIndex3 {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 3rem;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

@media (max-width: 1400px) {
  .viewIndex3 {
    width: 70%;
  }
}

@media (max-width: 1150px) {
  .viewIndex3 {
    width: 85%;
  }
}

@media (max-width: 900px) {
  .viewIndex3 {
    width: 95%;
    gap: 2rem;
  }
}

.viewIndex4 {
  display: flex;
  justify-content: center;
  gap: 3rem;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
}

@media (max-width: 1400px) {
  .viewIndex4 {
    width: 70%;
  }
}

@media (max-width: 1150px) {
  .viewIndex4 {
    width: 85%;
  }
}

@media (max-width: 900px) {
  .viewIndex4 {
    width: 95%;
    gap: 2rem;
  }
}

.creditsCardIndex3 {
  background-color: white;
  padding: 1.8rem 3rem;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 2.5rem;
  width: 30%;
  font-size: 2rem;
  color: #0e9d94;
}

.creditsCardIndex3 span {
  font-size: 1.8rem;
  line-height: 1.6;
  color: #212121;
}

@media (max-width: 1800px) {
  .creditsCardIndex3 span {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}

@media (max-width: 1150px) {
  .creditsCardIndex3 span {
    font-size: 1.45rem;
    line-height: 1.4;
  }
}

.cardIndex3 {
  background-color: white;
  padding: 4rem 3rem;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 30%;
}

.cardImgIndex3 {
  width: 10rem;
  padding: 1.6rem;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
}

.cardImg2Index3 {
  width: 18rem;
  padding: 1.6rem;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
}

.cardTextIndex3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: #111111;
  text-align: center;
}

.cardTextIndex3 a {
  text-decoration: none;
  background-color: #0e9d94;
  color: #fff;
  font-weight: 400;
  padding: 1rem 2rem;
  border-radius: 35px;
}

.creditsIndex3 {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.6rem;
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  background-color: #00000080;
  padding: 1.85rem 0;
}

@media (max-width: 1200px) {
  .creditsIndex3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 800px) {
  .creditsIndex3 {
    font-size: 1.4rem;
  }
}
